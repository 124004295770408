import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { GET_STORES } from '@/store/actions/stores';
import { GET_PRODUCTS, SET_SKU_SELECTED } from '@/store/actions/products';
import { lsSetSelectedSku, lsRemoveSelectedSku, lsSkuSelected } from '@/utils/sessionStorage';
import { storageAvailable } from '@/utils/localStore';
import { lsStoreSelected } from '../utils/localStore';
import { i18n } from '@/setup/i18n';

// Views
const Layout = () => import(/* webpackChunkName: "views/layout/main" */ '@/views/Layout/index');
const Error = () => import(/* webpackChunkName: "pages/views/error" */ '@/views/Pages/Error');
const PagesNotFound = () => import(/* webpackChunkName: "pages/views/404" */ '@/views/Pages/NotFound');
const ProductDetail = () => import(/* webpackChunkName: "views/product/detail" */ '@/views/Product/Detail');
const Stores = () => import(/* webpackChunkName: "views/stores" */ '@/views/Stores/store');
const ListProducts = () => import(/* webpackChunkName: "views/product/list" */ '@/views/Product/List');

// Redirect to the error page
const redirectToErrorPage = (next, resource) => next({ name: 'Error', params: { resource } });
// Redirect to the 404 page
const redirectTo404Page = (next, path) => next({ name: 'page-not-found', params: { 0: path } });

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/stores',
        meta: { titleKey: 'stores' },
        name: 'Stores',
        component: Stores,
        beforeEnter: (to, from, next) => {
          let { sku } = to.params;

          if (sku) {
            next();
          } else if (storageAvailable('localStorage')) {
            sku = lsSkuSelected();
            if (sku) {
              store
                .dispatch(SET_SKU_SELECTED, sku)
                .then(() => next({ name: 'Stores', params: { sku } }))
                .catch(err => {
                  console.log('Error set sku in store:' + err.message);
                  redirectToErrorPage(next);
                });
            } else {
              redirectTo404Page(next);
            }
          }
          //else redirectToErrorPage(next);
          else redirectTo404Page(next);
        }
      },
      {
        path: '/products',
        meta: { titleKey: 'products' },
        name: 'Products',
        component: ListProducts,
        beforeEnter: (to, from, next) => {
          let { group, storeCode } = to.params;

          if (group && storeCode) {
            next({
              params: {
                group,
                storeCode
              }
            });
          } else if (storageAvailable('localStorage')) {
            group = lsSkuSelected();
            const store = lsStoreSelected();
            if (group && store) {
              next({
                name: 'product-detail',
                params: {
                  sku: group,
                  storeCode: store.code
                }
              });
            } else redirectTo404Page(next);
          }
          //else redirectToErrorPage(next);
          else redirectTo404Page(next);
        }
      },
      {
        path: '/error',
        meta: { titleKey: 'error' },
        name: 'Error',
        component: Error
      },
      {
        path: '/:sku',
        name: 'pre-product-detail',
        beforeEnter(to, from, next) {
          const { sku } = to.params;

          if (!sku) return next({ name: 'page-not-found' });
          if (sku) {
            store
              .dispatch(SET_SKU_SELECTED, sku)
              .then(() => next({ name: 'Stores', params: { sku } }))
              .catch(err => {
                console.log('Error set sku in store:' + err.message);
                redirectToErrorPage(next);
              });
          }
        }
      },
      {
        path: '/:sku/:storeCode(\\d+)',
        meta: { titleKey: 'detail' },
        name: 'product-detail',
        component: ProductDetail,
        async beforeEnter(to, from, next) {
          const { sku, storeCode } = to.params;

          if (storeCode && sku) {
            next();
          } else {
            redirectToErrorPage(next);
          }
        }
      },
      {
        path: '/:group/:sku/:storeCode(\\d+)',
        meta: { titleKey: 'detail' },
        name: 'group-product-detail',
        component: ProductDetail,
        async beforeEnter(to, from, next) {
          const { group, sku, storeCode } = to.params;

          if (group && storeCode && sku) {
            next();
          } else {
            redirectToErrorPage(next);
          }
        }
      },
      {
        path: '*',
        meta: { titleKey: 'notFound' },
        name: 'page-not-found',
        component: PagesNotFound
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {

  const titleKey = to.meta.titleKey;
  const titleByKey = titleKey ? `| ${i18n.t(`title.${to.meta.titleKey}`)}` : '';
  document.title = `${i18n.t('title.app')} ${titleByKey}`;
  next();

});

router.beforeResolve((to, from, next) => {

  if (to.path === '/') {

    next();

  } else if (to.fullPath.indexOf('?') > 1) {

    const queryStringVars = to.fullPath.split('?');

    if (queryStringVars.length == 2 && queryStringVars[1].indexOf('&') > 1) {
      if (queryStringVars[1].indexOf('utm_source=QR&utm_medium=folleto_pro') == -1) {
        // Existe query string pero no es el correcto ir a page not found
        redirectTo404Page(next);

      } else {
        // Existen parametros seguimiento
        next();
      }
    } else {
      // Si no hay & no hay dos variables ir a page not found
      redirectTo404Page(next);
    }
  } else {
    router.push({
      name: to.name,
      params: to.params,
      query: Object.assign({}, router.query, { utm_source: 'QR', utm_medium: 'folleto_pro' })
    });
  }
});

export default router;
