export default {
  title: {
    app: 'Catálago PRO',
    error: 'Error',
    products: 'Productos',
    stores: 'Tiendas',
    detail: 'Detalle de producto',
    notFound: 'Producto no encontrado'
  },
  message: {
    generic: {
      loading: {
        data: 'Cargando datos'
      }
    },
    cookie: {
      title: 'Tú preferencia de cookies',
      description:
        // eslint-disable-next-line max-len
        'En leroymerlin.es usamos cookies, propias y de terceros, con distintas finalidades. Algunas de estas cookies son necesarias para el correcto funcionamiento de la Web, otras se emplean con finalidades analíticas. Al hacer click en “Aceptar” estarás aceptando la instalación de todas estas cookies. Si lo deseas, puedes rechazar el uso de todas haciendo click en “Cancelar”. Para obtener más información sobre el uso de las cookies y tus derechos, accede a nuestra <a href=" https://www.leroymerlin.es/politicadecookies" target="_blank">Política de Cookies</a>.'
    }
  },
  ui: {
    label: {
      store: {
        select: 'Seleccionar tienda',
        address: 'DIRECCIÓN',
        cityProvince: 'CIUDAD-PROVINCIA',
        community: 'COMUNIDAD',
        phone: 'TELÉFONO ATTN. CLIENTE PRO',
        webSite: 'SITIO WEB',
        moreInfo: 'Más información'
      },
      product: {
        offer: 'OFERTA',
        searchByReference: 'Buscar por referencia',
        inStock: 'en stock',
        uponRequest: 'Este producto sólo se trabaja en esta tienda bajo pedido',
        uponRequestShorten: 'Bajo pedido',
        notForSale: 'Este producto no está a la venta en esta tienda',
        notForSaleShorten: 'No a la venta',
        notAvailable: 'Actualmente no disponible',
        notAvailableShorten: 'No disponible'
      }
    },
    button: {
      btnChangeStore: 'Cambiar tienda',
      btnClose: 'Cerrar',
      btnAccept: 'Aceptar',
      btnCancel: 'Cancelar',
      btnBack: 'Volver a {packName}',
      btnBackGeneric: 'Volver al listado',
      btnSelect: 'Seleccionar tienda',
      btnSeePDF: 'Ver PDF',
      btnSeeVideo: 'Ver video',
      btnSearchByReference: 'Buscar',
      btnSearchAvailability: 'Consultar stock en todas las tiendas',
    },
    message: {
      ohNo: 'Oooopss!',
      sorry: '¡Disculpe las molestias!',
      productNotFound: 'El producto no está disponible para la tienda seleccionada.',
      error: 'Lo sentimos , ha ocurrido un error.'
    }
  },
  language: {
    en: 'Inglés',
    es: 'Español'
  }
};
