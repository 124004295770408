import { http } from '@/setup/http';

export default {
  storesData: async storeCode => {
    let stores = [];
    const api = storeCode ? `/stores/${storeCode}` : '/stores';
    await http.get(api).then(result => {
      stores = result.data;
    });
    return stores;
  }
};
