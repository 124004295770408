import axios from 'axios';
import NProgress from 'nprogress';

/* NProgress.configure({
  showSpinner: false
}); */

NProgress.configure({ ease: 'ease', speed: 500 }); // Adjust animation settings using easing (a CSS easing string) and speed (in ms). (default: ease and 200)
NProgress.configure({ trickleSpeed: 800 }); //Adjust how often to trickle/increment, in ms.
NProgress.configure({ showSpinner: false }); //Turn off loading spinner by setting it to false. (default: true)
NProgress.configure({ parent: '#nprogress-container' }); //specify this to change

export const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

http.interceptors.request.use(config => {
  NProgress.start();
  return config;
});

http.interceptors.response.use(
  response => {
    const loading = response.config.loading !== undefined ? response.config.loading : true;
    if (loading) {
      NProgress.done();
    }
    return Promise.resolve(response);
  },
  error => {
    NProgress.done();
    const originalRequest = error.config;
    if (error.response.status === 404 && !originalRequest._retry) {
      // if the error is 401 and hasent already been retried
      originalRequest._retry = true; // now it can be retried
    }

    // Do something with response error
    return Promise.reject(error);
  }
);
