import productService from '@/services/products';
import { GET_PRODUCTS, SET_PRODUCTS, SET_SKU_SELECTED } from '../actions/products';
import { lsSetSelectedSku } from '@/utils/sessionStorage';

const state = {
  sku: '',
  products: null
};
const getters = {
  getDataProducts: state => field => state[field]
};

const actions = {
  [GET_PRODUCTS]: ({ commit }, { sku, storeCode }) =>
    new Promise((resolve, reject) => {
      productService
        .productData(sku, storeCode)
        .then(resp => {
          if (!resp) throw new Error('Error getting products data');
          const { data } = resp;
          if (data && data.items.length > 0) {
            commit(SET_PRODUCTS, data);
            commit(SET_SKU_SELECTED, sku);
            resolve(data);
          } else {
            throw new Error('Error getting products data 404');
          }
        })
        .catch(err => {
          console.log(`Error get products: ${err.message}`);
          reject(err);
        });
    }),
  [SET_SKU_SELECTED]: ({ commit }, sku) => {
    commit(SET_SKU_SELECTED, sku);
  }
};

const mutations = {
  [SET_PRODUCTS]: (state, productsData) => {
    state.products = productsData;
  },
  [SET_SKU_SELECTED]: (state, sku) => {
    state.sku = sku;
    lsSetSelectedSku(sku);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
