import { http } from '@/setup/http';

export default {
  productData: async (sku, storeCode) =>
    await http.get(`/products/${sku}/${storeCode}`).catch(err => {
      console.log('Error GET product data:' + err.message);
      // return err.message;
      // this.$router
      return Promise.reject(err);
    })
};
