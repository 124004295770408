<template>
  <div id="app" class="app">
    <div id="nprogress-container" />
    <div class="ml-container">
      <div class="ml-flexy header">
        <div class="ml-flexy__col--1of3">
          <div class="logo">
            <div class="logo__leroy">
              <img src="@/assets/img/logo_lm1.png" alt="" />
            </div>
          </div>
        </div>
        <div class="ml-flexy__col--fill">
          <div class="logo">
            <div class="logo__pro"><img src="@/assets/img/PRO_LEROY_MERLIN.png" alt="" /></div>
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      imgLogo: {
        type: String,
        default: require('@/assets/img/logo_lm1.png')
      },
      morePro: {
        type: String,
        default: require('@/assets/img/PRO_LEROY_MERLIN.png')
      }
    };
  }
};
</script>
