export default {
  title: {
    app: 'Catálago PRO',
    error: 'Error',
    products: 'Products',
    stores: 'Stores',
    detail: 'Product detail',
    notFound: 'Product not found'
  },
  message: {
    generic: {
      loading: {
        data: 'Loading data'
      }
    },
    cookie: 'We use cookies...'
  },
  ui: {
    label: {
      store: {
        select: 'Selecct store',
        address: 'ADDRESS',
        cityProvince: 'CITY-PROVINCE',
        community: 'COMMUNITY',
        phone: 'CUSTOMER SERVICE',
        webSite: 'WEB SITE',
        moreInfo: 'More information'
      },
      product: {
        offer: 'OFFER',
        searchByReference: 'Search by reference',
        inStock: 'in stock',
        uponRequest: 'This product is only available in this store upon request',
        uponRequestShorten: 'Upon request',
        notForSale: 'This product is not for sale in this store',
        notForSaleShorten: 'Not for sale',
        notAvailable: 'Currently not available',
        notAvailableShorten: 'Not available'
      }
    },
    button: {
      btnChangeStore: 'Select store',
      btnClose: '',
      btnAccept: 'Accept',
      btnCancel: 'Cancel',
      btnBack: 'Back to {packName}',
      btnBackGeneric: 'Back to list',
      btnSelect: 'Select store',
      btnSeePDF: 'See PDF',
      btnSeeVideo: 'See video',
      btnSearchByReference: 'Search',
      btnSearchAvailability: 'Search stock in all stores',
    },
    message: {
      ohNo: 'Oooopss!',
      sorry: 'Sorry for the inconvenience!',
      productNotFound: 'The product is not available for the selected store.',
      error: 'Sorry an error occurred.'
    }
  },
  language: {
    en: 'English',
    es: 'Spanish'
  }
};
