// Privates local storage
const localStorageGetObject = key => JSON.parse(localStorage.getItem(key));
const localStorageSetItem = (key, value) => localStorage.setItem(key, JSON.stringify(value));
const localStorageRemoveItem = key => localStorage.removeItem(key);

export const lsSetSelectedStore = value => localStorageSetItem('store', JSON.stringify(value));
export const lsSetCookie = value => localStorageSetItem('cookie', JSON.stringify(value));
export const lsRemoveSelectedStore = () => localStorageRemoveItem('store');
export const lsStoreSelected = () => {
  const store = localStorageGetObject('store');
  return store ? JSON.parse(store) : null;
};

export const lsGetCookieConsent = () => {
  const cookie = localStorageGetObject('cookie');
  return cookie ? JSON.parse(cookie) : null;
};

export const storageAvailable = type => {
  try {
    var storage = window[type],
      x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      (e.code === 22 ||
        e.code === 1014 ||
        e.name === 'QuotaExceededError' ||
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      storage.length !== 0
    );
  }
};
