const sessionStorageGetObject = key => JSON.parse(sessionStorage.getItem(key));
const sessionStorageSetItem = (key, value) => sessionStorage.setItem(key, JSON.stringify(value));
const sessionStorageRemoveItem = key => sessionStorage.removeItem(key);

export const lsSetSelectedSku = value => sessionStorageSetItem('sku', JSON.stringify(value));
export const lsRemoveSelectedSku = () => sessionStorageRemoveItem('sku');
export const lsSkuSelected = () => {
  const sku = sessionStorageGetObject('sku');
  return sku ? JSON.parse(sku) : null;
};
