import stores from '@/services/stores';
import store from '..';
import { GET_STORES, SET_STORES, SET_STORE_SELECTED } from '../actions/stores';

const state = {
  stores: [],
  selected: {}
};
const getters = {
  getDataStores: state => field => state[field]
};

const actions = {
  [SET_STORE_SELECTED]: ({ commit }, store) => commit(SET_STORE_SELECTED, store),
  [GET_STORES]: ({ commit }, storeCode) =>
    new Promise((resolve, reject) => {
      stores
        .storesData(storeCode)
        .then(resp => {
          if (!resp) throw new Error('Error getting store data');
          const stores = resp.stores;
          if (storeCode) {
            stores.forEach(store => {
              if (store.storeInfo.code === storeCode) commit(SET_STORE_SELECTED, store.storeInfo);
            });
          } else {
            commit(SET_STORES, stores);
          }

          resolve(stores);
        })
        .catch(err => {
          console.log(`Error get stores: ${err.message}`);
          reject(err);
        });
    })
};

const mutations = {
  [SET_STORES]: (state, stores) => {
    state.stores = stores;
  },
  [SET_STORE_SELECTED]: (state, store) => {
    state.selected = store;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
