import Vue from 'vue';
import Vuex from 'vuex';
import stores from './modules/stores';
import products from './modules/products';

Vue.use(Vuex);
Vue.config.devtools = true;

const storeVuex = new Vuex.Store({
  state: {},
  actions: {},
  mutations: {},
  modules: {
    stores,
    products
  }
});

export { storeVuex as default };
