import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import en from '@/locale/en';
import es from '@/locale/es';

Vue.use(VueI18n);

const messages = {
  en,
  es
};

const locale = document.querySelector('html').getAttribute('lang') || 'es';

export const i18n = new VueI18n({
  locale, // set locale
  fallbackLocale: locale,
  messages // set locale messages
});

const loadedLanguages = ['es']; // our default language that is prelaoded

const setI18nLanguage = lang => {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
};

export const loadLanguageAsync = lang => {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "lang-[request]" */ `@/locale/${lang}`).then(msgs => {
        i18n.setLocaleMessage(lang, msgs.default);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
      });
    }
    return Promise.resolve(setI18nLanguage(lang));
  }
  return Promise.resolve(lang);
};
