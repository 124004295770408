import Vue from 'vue';

// ---------------------------------------------------------------------------
// Register mozaic components, they start with 'm-', example: m-button
// ---------------------------------------------------------------------------
Vue.component('m-field', () =>
  import(
    /* webpackChunkName: "components/mozaic/field" */ '@mozaic-ds/vue/src/components/field/MField'
  )
);
Vue.component('m-button', () =>
  import(
    /* webpackChunkName: "components/mozaic/button" */ '@mozaic-ds/vue/src/components/button/MButton'
  )
);
Vue.component('m-select', () =>
  import(
    /* webpackChunkName: "components/mozaic/select" */ '@mozaic-ds/vue/src/components/select/MSelect'
  )
);
Vue.component('m-checkbox', () =>
  import(
    /* webpackChunkName: "components/mozaic/checkbox" */ '@mozaic-ds/vue/src/components/checkbox/MCheckbox'
  )
);
Vue.component('m-accordion', () =>
  import(
    /* webpackChunkName: "components/mozaic/accordion" */ '@mozaic-ds/vue/src/components/accordion/MAccordion'
  )
);
Vue.component('m-toggle', () =>
  import(
    /* webpackChunkName: "components/mozaic/toggle" */ '@mozaic-ds/vue/src/components/toggle/MToggle'
  )
);
Vue.component('m-icon', () =>
  import(
    /* webpackChunkName: "components/mozaic/icon" */ '@mozaic-ds/vue/src/components/icon/MIcon'
  )
);
Vue.component('m-link', () =>
  import(
    /* webpackChunkName: "components/mozaic/link" */ '@mozaic-ds/vue/src/components/link/MLink'
  )
);
Vue.component('m-modal', () =>
  import(
    /* webpackChunkName: "components/mozaic/modal" */ '@mozaic-ds/vue/src/components/modal/MModal'
  )
);
Vue.component('m-hero', () =>
  import(
    /* webpackChunkName: "components/mozaic/hero" */ '@mozaic-ds/vue/src/components/hero/MHero'
  )
);
Vue.component('m-card', () =>
  import(
    /* webpackChunkName: "components/mozaic/hero" */ '@mozaic-ds/vue/src/components/card/MCard'
  )
);
Vue.component('m-radio', () =>
  import(
    /* webpackChunkName: "components/mozaic/hero" */ '@mozaic-ds/vue/src/components/radio/MRadio'
  )
);
Vue.component('m-pagination', () =>
  import(
    /* webpackChunkName: "components/mozaic/pagination" */ '@mozaic-ds/vue/src/components/pagination/MPagination'
  )
);
Vue.component('m-text-input', () =>
  import(
    /* webpackChunkName: "components/mozaic/text-inpunt" */ '@mozaic-ds/vue/src/components/textinput/MTextInput'
  )
);
Vue.component('m-notification', () =>
  import(
    /* webpackChunkName: "components/notification" */ '@mozaic-ds/vue/src/components/notification/MNotification'
  )
);
Vue.component('m-card', () =>
  import(/* webpackChunkName: "components/card" */ '@mozaic-ds/vue/src/components/card/MCard')
);
Vue.component('m-flag', () =>
  import(/* webpackChunkName: "components/flag" */ '@mozaic-ds/vue/src/components/flag/MFlag')
);
Vue.component('m-layer', () =>
  import(/* webpackChunkName: "components/layer" */ '@mozaic-ds/vue/src/components/layer/MLayer')
);
Vue.component('m-tooltip', () =>
  import(
    /* webpackChunkName: "components/tooltip" */ '@mozaic-ds/vue/src/components/tooltip/MTooltip'
  )
);

// ---------------------------------------------------------------------------
// Register other components
// ---------------------------------------------------------------------------
Vue.component('sidebar', () =>
  import(/* webpackChunkName: "components/sidebar" */ '@/components/Sidebar/index')
);
Vue.component('sidebar-link', () =>
  import(/* webpackChunkName: "components/sidebar-link" */ '@/components/Sidebar/SidebarLink')
);

Vue.component('spinner', () =>
  import(/* webpackChunkName: "components/stock-dealer/spinner" */ '@/components/Spinner/')
);
Vue.component('spinner-layout', () =>
  import(
    /* webpackChunkName: "components/stock-dealer/spinner-layout" */ '@/components/SpinnerLayout/'
  )
);
