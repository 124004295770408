import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@mozaic-ds/vue/dist/mozaic-vue.css';
import '@/setup/components';
import './assets/main.scss';
import 'nprogress/nprogress.css';

import { i18n } from '@/setup/i18n';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
  i18n
}).$mount('#app');
